import React from 'react';
import PropTypes from 'prop-types';
import SignUpCompanyLeadForm from "../forms/instances/SignUpCompanyLeadForm/SignUpCompanyLeadForm";
import Title from "../components/typography/Title";
import Block, {BlockPropTypeConfig} from "./Block";
import BlockSection from "./BlockSection";
import FootnoteWithIcon, {FootnoteWithIconProps} from "../components/FootnoteWithIcon";

import enMessages from '../i18n/main/en.json'
import ruMessages from '../i18n/main/ru.json'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const PAGE_HEADER_BLOCK_RENDER_DATA = {
    index: {
        rootClasses: 'Block_theme_indexHeader',
        titleStyleNumb: '1',
        textClasses: 'text1'
    },
    typical: {
        rootClasses: 'Block_theme_pageHeader',
        titleStyleNumb: '4',
        textClasses: 'text2'
    },
    simple: {
        rootClasses: 'Block_theme_pageHeader Block_theme_CenterAlign',
        titleStyleNumb: '4',
        textClasses: 'text2 Block-Text-CenterAlign'
    }
};

const PageHeaderBlock = ({ config = {}, title, text, theme = 'typical', footnotes, locale = 'ru', submitFormCaption, heroBanner, classNameBlock, titleColor, signUpForm = null }) => {
    const renderInfo = React.useMemo(() => {
        return PAGE_HEADER_BLOCK_RENDER_DATA[theme];
    }, [theme]);

    const usedConfig = React.useMemo(() => {
        const newConfig = {...config};
        newConfig.baseBorder = theme === 'simple' ? false : true;

        if (!newConfig.animation && theme !== 'simple') {
            newConfig.animation = theme === 'index' ? '1' : '3';
        }

        return newConfig;
    }, [config, theme]);

    return (
        <Block config={usedConfig} className={renderInfo.rootClasses}>
            <div className="container">
                <BlockSection className={classNameBlock}>
                    <Title level="h1" className="Block-Title" color={titleColor} headerStyleNumb={renderInfo.titleStyleNumb} innerHTML={title} />
                    <div className={`Block-Text ${renderInfo.textClasses}`} dangerouslySetInnerHTML={{__html: text}} />
                    {footnotes && (
                        <div className="Block-Footnotes">
                            {footnotes.map((footnote, i) => (
                                <FootnoteWithIcon key={i} {...footnote} />
                            ))}
                        </div>
                    )}
                    <div className="Block-Form">
                        {signUpForm || <SignUpCompanyLeadForm textSubmit={submitFormCaption ? submitFormCaption : messages[locale].textSubmit}/> }
                    </div>
                    {heroBanner && (
                        <div className="ImageHeroBanner">
                            <img src={heroBanner} />
                        </div>
                    )}
                </BlockSection>
            </div>
        </Block>
    );
};

export const PageHeaderBlockPropsType = {
    title: PropTypes.string,
    locale: PropTypes.string,
    text: PropTypes.string,
    theme: PropTypes.oneOf(['index', 'typical']),
    config: BlockPropTypeConfig,
    footnotes: PropTypes.arrayOf(PropTypes.shape(FootnoteWithIconProps)),
    signUpForm: PropTypes.instanceOf(React.Component),
};

PageHeaderBlock.propTypes = PageHeaderBlockPropsType;

export default PageHeaderBlock;
