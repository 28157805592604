import React from 'react';
import PropTypes from 'prop-types';

import '../styles/components/footnoteWithIcon.scss';

const FootnoteWithIcon = ({ icon, text }) => {
    return (
        <div className="FootnoteWithIcon">
            <div className="FootnoteWithIcon-Icon">
                <img src={icon} alt="icon" />
            </div>
            <div className="FootnoteWithIcon-Text" dangerouslySetInnerHTML={{__html: text}} />
        </div>
    )
};

export const FootnoteWithIconProps = {
    icon: PropTypes.string,
    text: PropTypes.string
};

FootnoteWithIcon.propTypes = FootnoteWithIconProps;

export default FootnoteWithIcon;
